import { Box, Button, Text } from "@chakra-ui/react";
import React, { FC } from "react";

//local
import { OrderCleaningSvg } from "../../assets/svg/OrderCleaningSvg";
import { useVisibility } from "../../config/hooks";

type IProps = {
  onOpen: () => void;
};

const HeroTexts: FC<IProps> = ({ onOpen }) => {
  const { ref, isVisible } = useVisibility();

  return (
    <Box
      pt="140px"
      maxW="567px"
      fontFamily="Urbanist"
      position="sticky"
      zIndex="1"
      ref={ref}
    >
      <Text
        fontWeight="500"
        fontSize={{ sm: "19.72px", base: "15px" }}
        lineHeight={{ sm: "29.58px", base: "18px" }}
        color="rgba(17, 149, 255, 1)"
        className={isVisible ? "fade-down-right" : ""}
      >
        Welcome to Maxi Cleaning Solution
      </Text>
      <Text
        fontWeight="700"
        fontSize={{ md: "59.17px", sm: "46px", base: "35px" }}
        lineHeight={{ md: "61.63px", sm: "54px", base: "42px" }}
        color="rgba(41, 41, 41, 1)"
        my="30px"
        className={isVisible ? "fade-down-right" : ""}
      >
        Your Trusted House Cleaning Services!
      </Text>
      <Text
        fontWeight="500"
        fontSize={{ sm: "19.72px", base: "15px" }}
        lineHeight={{ sm: "29.58px", base: "18px" }}
        color="rgba(17, 149, 255, 1)"
        maxW={{ sm: "70%", md: "100%", base: "300px" }}
        className={isVisible ? "zoom-in" : ""}
      >
        We understand the importance of a clean home, and our highly trained
        team is here to provide top-quality house cleaning services that exceed
        your expectations.
      </Text>
      <Button
        mt="35px"
        w={{ sm: "394px", base: "240px" }}
        fontSize={{ base: "14px", sm: "19.72px" }}
        gap={{ sm: "28.35px", base: "16px" }}
        borderRadius={{ sm: "61.63px", base: "30px" }}
        p={{
          sm: "28.49px 43.14px 28.49px 43.14px",
          base: "11px 25px 11px 25px",
        }}
        bg="linear-gradient(14deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 15%, rgba(0,212,255,1) 100%)"
        colorScheme="blue.500"
        onClick={onOpen}
        className={isVisible ? "fade-down-bottom" : ""}
      >
        <OrderCleaningSvg />
        <span>Order cleaning</span>
      </Button>
    </Box>
  );
};

export default HeroTexts;
