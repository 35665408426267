import { Container } from "@chakra-ui/react";
import React, { FC, ReactNode } from "react";

type IProps = { children: ReactNode };

const ContainerComponent: FC<IProps> = ({ children }) => {
  return (
    <Container
      maxW={{
        base: "100%",
        md: "98%",
        lg: "95%",
        xl: "1120px",
        "2xl": "1360px",
      }}
      mx="auto"
    >
      {children}
    </Container>
  );
};

export default ContainerComponent;
