import { Text, TextProps } from "@chakra-ui/react";
import React, { FC } from "react";

//local
import { useVisibility } from "../../config/hooks";

type ITextTitle = {
  text: string;
  size?: TextProps["fontSize"];
  line?: TextProps["lineHeight"];
  color?: string;
};

const TextTitle: FC<ITextTitle> = ({ text, size, line, color }) => {
  const textArr = text.split("");

  const { ref, isVisible } = useVisibility();

  return (
    <Text
      ref={ref}
      fontSize={size || { md: "48px", sm: "40px", base: "32px" }}
      fontWeight={{ md: "700", base: "800" }}
      lineHeight={line || { md: "58px", sm: "48px", base: "38.4px" }}
      textAlign="center"
      color={color}
      className={isVisible ? "text-title" : ""}
    >
      {textArr.map((el, idx) => (
        <span key={idx}>{el}</span>
      ))}
    </Text>
  );
};

export default TextTitle;
