import Henry from "../../assets/img/Henry.png";
import Steward from "../../assets/img/Steward.png";
import Bell from "../../assets/img/Bell.png";

export const dataListCustomer = [
  {
    name: "Courtney Henry",
    about:
      "Aliqua id fugiat nostrud irure ex duis ea quis quis ad et. Sunt qui esse pariatur duis deserunt mollit dolore cillum minim tempor enim.",
    image: Henry,
  },
  {
    name: "Darrell Steward",
    about:
      "Aliqua id fugiat nostrud irure ex duis ea quis quis ad et. Sunt qui esse pariatur duis deserunt mollit dolore cillum minim tempor enim.",
    image: Steward,
  },
  {
    name: "Jerome Bell",
    about:
      "Aliqua id fugiat nostrud irure ex duis ea quis quis ad et. Sunt qui esse pariatur duis deserunt mollit dolore cillum minim tempor enim.",
    image: Bell,
  },
];
