export const EmailIconSvg = () => {
  return (
    <svg
      width="25"
      height="21"
      viewBox="0 0 25 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.25 0.5H3.75C2.75544 0.5 1.80161 0.900089 1.09835 1.61225C0.395088 2.32442 0 3.29032 0 4.29747V16.9557C0 17.9628 0.395088 18.9287 1.09835 19.6409C1.80161 20.3531 2.75544 20.7532 3.75 20.7532H21.25C22.2446 20.7532 23.1984 20.3531 23.9017 19.6409C24.6049 18.9287 25 17.9628 25 16.9557V4.29747C25 3.29032 24.6049 2.32442 23.9017 1.61225C23.1984 0.900089 22.2446 0.5 21.25 0.5ZM21.25 3.03165L13.125 8.68987C12.935 8.80097 12.7194 8.85946 12.5 8.85946C12.2806 8.85946 12.065 8.80097 11.875 8.68987L3.75 3.03165H21.25Z"
        fill="#1155CB"
      />
    </svg>
  );
};
