import { Modal, ModalBody, ModalContent, ModalOverlay } from "@chakra-ui/react";
import { FC, ReactNode } from "react";

type IProps = {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
};

const ModalComponent: FC<IProps> = ({ isOpen, onClose, children }) => {
  return (
    <Modal onClose={onClose} isOpen={isOpen} isCentered>
      <ModalOverlay />
      <ModalContent
        maxW="629px"
        borderTopRadius="30px"
        borderBottomRadius={{ md: "30px" }}
        transition="transform 0.3s ease-out"
      >
        <ModalBody p="0">{children}</ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ModalComponent;
