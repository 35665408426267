import React, { FC, useState } from "react";
import { Box, FormLabel, Input } from "@chakra-ui/react";
import LeftNav from "./LeftNav";
import RightNav from "./RightNav";

const BurgerBtn: FC = () => {
  const [isOpen, setOpen] = useState<boolean>(false);
  const handleCheked = () => {
    setOpen(!isOpen);
  };
  return (
    <>
      <Input
        id="menu__toggle"
        type="checkbox"
        defaultChecked={isOpen}
        onChange={handleCheked}
        opacity="0"
      />
      <FormLabel
        display="flex"
        alignItems="center"
        position="fixed"
        top="20px"
        left="20px"
        width="32px"
        h="32px"
        cursor="pointer"
        zIndex="1"
        htmlFor="menu__toggle"
      >
        <span
          className="line1"
          style={{
            transform: `${isOpen ? "rotate(45deg) translate(10px, 12px)" : ""}`,
            backgroundColor: `${isOpen ? "rgba(255, 255, 255, 1)" : ""}`,
          }}
        ></span>
        <span
          className="line2"
          style={{ opacity: `${isOpen ? "0" : "1"}` }}
        ></span>
        <span
          className="line3"
          style={{
            transform: `${
              isOpen ? "rotate(135deg) translate(-8px, 10px)" : ""
            }`,
            backgroundColor: `${isOpen ? "rgba(255, 255, 255, 1)" : ""}`,
          }}
        ></span>
      </FormLabel>
      <Box
        display="block"
        position="fixed"
        top="0"
        left={isOpen ? "0" : "-100%"}
        width="100%"
        height="100%"
        m="0"
        padding="100px 20px"
        bg="rgba(17, 85, 203, 1)"
        boxShadow="1px 0px 6px rgba(0, 0, 0, 0.2)"
        transitionDuration="0.25s"
      >
        <LeftNav onClick={handleCheked} />
        <RightNav onClick={handleCheked} />
      </Box>
    </>
  );
};

export default BurgerBtn;
