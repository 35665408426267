import { Box, Text } from "@chakra-ui/react";
import React, { FC } from "react";

const BlackComponent: FC = () => {
  const date = new Date();
  return (
    <Box bg="black" color="rgba(255, 255, 255, 1)" py="25px">
      <Text
        textAlign="center"
        fontFamily="Prompt"
        fontSize="11px"
        fontWeight="400"
        lineHeight="19px"
      >
        © {date.getFullYear()} Cleaning Service. All Rights Reserved.
      </Text>
    </Box>
  );
};

export default BlackComponent;
