import { Box, SimpleGrid, Text } from "@chakra-ui/react";
import React, { FC } from "react";

//local
import SectionComponent from "../ui/SectionComponent";
import { dataList } from "./dataList";
import TextTitle from "../ui/TextTitle";

const UsUnique: FC = () => {
  return (
    <SectionComponent idName="unique">
      <Box pt="100px" fontFamily="Urbanist">
        <TextTitle
          size="32px"
          color="rgba(0, 0, 0, 1)"
          line="38px"
          text="WHAT MAKES US UNIQUE"
        />
        <SimpleGrid columns={{ xl: 4, lg: 3, md: 2, base: 1 }} pt="40px">
          {dataList.map((el, idx) => (
            <Box
              key={idx}
              boxShadow="0px 2px 10px 0px rgba(0, 0, 0, 0.1)"
              border="1px solid rgba(17, 85, 203, 0.1)"
              maxW={{ lg: "270px", md: "340px", sm: "420px", base: "100%" }}
              mx={{ base: "auto", lg: "6px" }}
              mb="20px"
              minH={{ md: "", base: "218px" }}
              px="0.5rem"
              py="2rem"
              borderRadius="9px"
            >
              <Text
                textAlign="center"
                color="rgba(17, 85, 203, 1)"
                fontWeight="700"
                fontSize="19px"
                lineHeight="24px"
              >
                {el.title}
              </Text>
              <Text
                fontWeight="400"
                fontSize="19px"
                lineHeight="24px"
                maxW="90%"
                mx="auto"
                mt="10px"
                textAlign="center"
                color="rgba(0, 0, 0, 1)"
              >
                {el.description}
              </Text>
            </Box>
          ))}
        </SimpleGrid>
      </Box>
    </SectionComponent>
  );
};

export default UsUnique;
