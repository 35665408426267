import React, { FC } from "react";
import { Box, SimpleGrid, Text } from "@chakra-ui/react";

//local
import ContainerComponent from "../ui/ContainerComponent";
import BlackComponent from "./BlackComponent";
import { navigateToUrl } from "../../config/helpers";
import { LogoIconSvg } from "../../assets/svg/LogoIconSvg";
import { ADDRESS, EMAIL, PHONE } from "../../config/constants";

const Footer: FC = () => {
  return (
    <footer id="footer">
      <ContainerComponent>
        <Box
          borderTop="1px solid rgba(17, 85, 203, 0.1)"
          py="25px"
          fontFamily="Prompt"
          display="flex"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Box
            display={{ base: "none", md: "block" }}
            width={{ lg: "25%", md: "20%" }}
          >
            <LogoIconSvg />
          </Box>
          <Box
            w={{ lg: "70%", md: "90%", base: "100%" }}
            display="flex"
            justifyContent="space-between"
            fontFamily="Prompt"
            flexWrap="wrap"
            fontSize="11px"
            fontWeight="400"
            lineHeight="19px"
          >
            <Box maxW="180px">
              <Text
                color="rgba(0, 0, 0, 1)"
                fontWeight="600"
                fontSize="18px"
                lineHeight="27.22px"
              >
                Contact:
              </Text>
              <Box pt="20px">
                <Text
                  cursor="pointer"
                  mb="5px"
                  onClick={() => navigateToUrl(`mailto:${EMAIL}`, true)}
                >
                  {EMAIL}
                </Text>
                <Text
                  cursor="pointer"
                  mb="5px"
                  onClick={() => navigateToUrl(`tel:${PHONE}`, true)}
                >
                  {PHONE}
                </Text>
              </Box>
            </Box>
            <Box maxW="193px" pl="10px">
              <Text
                color="rgba(0, 0, 0, 1)"
                fontWeight="600"
                fontSize="18px"
                lineHeight="27.22px"
              >
                Address:
              </Text>
              <Box pt="20px">
                <Text
                  cursor="pointer"
                  mb="5px"
                  onClick={() => navigateToUrl("https://www.google.com/maps")}
                >
                  {ADDRESS}
                </Text>
              </Box>
            </Box>
            <Box w={{ md: "299px", bade: "100%" }}>
              <Text
                color="rgba(0, 0, 0, 1)"
                fontWeight="600"
                fontSize="18px"
                lineHeight="27.22px"
                ml="10px"
              >
                Social:
              </Text>
              <SimpleGrid columns={{ lg: 3, md: 2, base: 2 }} pt="20px">
                <Text
                  cursor="pointer"
                  mb="5px"
                  ml="10px"
                  onClick={() =>
                    navigateToUrl("https://twitter.com/@MaxiCleaningInc")
                  }
                >
                  Twitter
                </Text>
                <Text
                  cursor="pointer"
                  mb="5px"
                  ml="10px"
                  onClick={() =>
                    navigateToUrl("https://www.instagram.com/maxicleaning.us")
                  }
                >
                  Instagram
                </Text>

                <Text
                  cursor="pointer"
                  mb="5px"
                  ml="10px"
                  onClick={() =>
                    navigateToUrl(
                      "https://www.facebook.com/MaxiCleaningSolution"
                    )
                  }
                >
                  Facebook
                </Text>
                <Text
                  cursor="pointer"
                  mb="5px"
                  ml="10px"
                  onClick={() =>
                    navigateToUrl(
                      "https://www.linkedin.com/company/maxicleaningsolution/"
                    )
                  }
                >
                  Linkedin
                </Text>
                <Text
                  cursor="pointer"
                  mb="5px"
                  ml="10px"
                  onClick={() => navigateToUrl("https://wa.me/18724008209")}
                >
                  Whatsapp
                </Text>
              </SimpleGrid>
            </Box>
          </Box>
        </Box>
      </ContainerComponent>
      <BlackComponent />
    </footer>
  );
};

export default Footer;
