import React, { FC } from "react";
import { Box, Image, Text } from "@chakra-ui/react";
import { ICustomer } from "../../config/types";

type IProps = {
  elem: ICustomer;
};

const CustomCard: FC<IProps> = ({ elem }) => {
  const { name, about, image } = elem;
  return (
    <Box
      px="15px"
      py="40px"
      mx="10px"
      mt="40px"
      rounded="8px"
      position="relative"
      border="1px solid rgba(222, 222, 222, 1)"
      color="rgba(41, 41, 41, 1)"
      fontFamily="Urbanist"
    >
      <Image
        src={image}
        alt={name}
        position="absolute"
        top="-40px"
        left="50%"
        transform="translateX(-50%)"
      />
      <Text
        mt="10px"
        textAlign="center"
        fontSize="20px"
        lineHeight="20px"
        fontWeight="600"
      >
        {name}
      </Text>
      <Text
        my="15px"
        textAlign="center"
        fontWeight="400"
        lineHeight="20px"
        fontSize="14px"
      >
        {about}
      </Text>
    </Box>
  );
};

export default CustomCard;
