import React, { FC } from "react";
import { LoadingIconSvg } from "../../assets/svg/LoadingIconSvg";

const Loading: FC = () => {
  return (
    <div id="loading">
      <div className="loading">
        <div className="loading__ring">
          <LoadingIconSvg />
        </div>
        <div className="loading__ring">
          <LoadingIconSvg />
        </div>
      </div>
    </div>
  );
};

export default Loading;
