import { FC } from "react";
import { leftNavList } from "../../config/navLink";
import TextNav from "../ui/TextNav";

type IProps = {
  onClick?: () => void;
};

const LeftNav: FC<IProps> = ({ onClick }) => {
  return (
    <>
      {leftNavList.map((el, idx) => (
        <TextNav key={idx} nav={el} onClick={onClick} />
      ))}
    </>
  );
};

export default LeftNav;
