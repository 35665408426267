import React, { FC } from "react";
import { ISectionComponent } from "../../config/types";
import ContainerComponent from "./ContainerComponent";

const SectionComponent: FC<ISectionComponent> = ({ idName, children }) => {
  return (
    <section id={idName}>
      <ContainerComponent>{children}</ContainerComponent>
    </section>
  );
};

export default SectionComponent;
