import React, { FC } from "react";
import { Box, Button, Image, Text } from "@chakra-ui/react";

//local
import SectionComponent from "../ui/SectionComponent";
import { downloadFile } from "../../config/helpers";
import TextTitle from "../ui/TextTitle";
import { dataList } from "./dataList";

const OurService: FC = () => {
  return (
    <SectionComponent idName="service">
      <Box pt="100px" fontFamily="Urbanist" color="rgba(56, 53, 53, 1)">
        <TextTitle text="Our Services" />
        <Box
          display="flex"
          flexWrap="wrap"
          justifyContent="space-between"
          pt="40px"
        >
          {dataList.map((el, idx) => (
            <Box
              key={idx}
              display="flex"
              flexDirection="column"
              maxW={{ lg: "270px", md: "340px", sm: "420px", base: "100%" }}
              mx={{ base: "auto", lg: "6px" }}
              mb="20px"
              boxShadow="0px 2px 25px 0px rgba(0, 0, 0, 0.1)"
              border="1px solid rgba(17, 85, 203, 0.1)"
              borderRadius="9px"
              overflow="hidden"
            >
              <Image borderRadius="9px" src={el.image} alt={el.image} />
              <Box p="1rem">
                <Text fontWeight="600" fontSize="22px" lineHeight="27.75px">
                  {el.title}
                </Text>
                <Text
                  fontWeight="400"
                  fontSize="14px"
                  lineHeight="16.8px"
                  mt="5px"
                  mb="10px"
                >
                  {el.description}
                </Text>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="flex-end"
                >
                  <Button
                    w="195px"
                    h="50px"
                    mx="auto"
                    borderRadius="50px"
                    bg="linear-gradient(63deg, rgba(37, 158, 255, 1) 2%, rgba(19,105,181,1) 100%)"
                    fontSize="18px"
                    fontWeight="700"
                    lineHeight="22px"
                    color="rgba(255, 255, 255, 1)"
                    colorScheme="blue.500"
                    onClick={() => downloadFile(el.document, el.title)}
                  >
                    Get more details
                  </Button>
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </SectionComponent>
  );
};

export default OurService;
