export const dataList = [
  {
    title: "Quality Service",
    description: "We Use Top-Notch Tools And Products For Meticulous Cleaning.",
  },
  {
    title: "Personalized Approach",
    description: "Tailored Cleaning Plans To Meet Your Unique Needs.",
  },
  {
    title: "Trustworthy Staff",
    description:
      "Our Screened And Trained Cleaners Prioritize Your Safery And Respect Your Property.",
  },
  {
    title: "Reliability And Convenience",
    description:
      "Flexible Scheduling For One-Time Deep Cleans Or Regular Maintenance. Your Time Matters To Us.",
  },
];
