export const handleScroll = (idName: string) => {
  const element = document.getElementById(idName);
  if (element) {
    element.scrollIntoView({ behavior: "smooth" });
  }
};

export const navigateToUrl = (url: string, isMobile?: boolean) => {
  if (isMobile) {
    window.open(url, "_self");
  } else {
    window.open(url, "_blank");
  }
};

export const downloadFile = (fileUrl: string, fileName: string) => {
  const link = document.createElement("a");
  link.href = fileUrl;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
