export const OrderCleaningSvg = () => {
  return (
    <svg
      width="26"
      height="29"
      viewBox="0 0 26 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.7584 11.2643V7.06655C23.7584 5.52096 22.4875 4.26802 20.9195 4.26802H3.88603C2.31814 4.26802 1.04712 5.52096 1.04712 7.06655V23.8577C1.04712 25.4034 2.31814 26.6563 3.88603 26.6563H8.14441M16.6612 1.46948V7.06655M8.14441 1.46948V7.06655M1.04712 12.6636H8.14441M18.0806 18.5405V20.9018L19.3581 22.3186M24.4682 21.0592C24.4682 24.5368 21.6084 27.3559 18.0806 27.3559C14.5528 27.3559 11.6931 24.5368 11.6931 21.0592C11.6931 17.5816 14.5528 14.7625 18.0806 14.7625C21.6084 14.7625 24.4682 17.5816 24.4682 21.0592Z"
        stroke="white"
        strokeWidth="2.09557"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
