import { Text } from "@chakra-ui/react";
import React, { FC } from "react";
import { handleScroll } from "../../config/helpers";

type IProps = {
  nav: string;
  onClick?: () => void;
};

const TextNav: FC<IProps> = ({ nav, onClick }) => {
  return (
    <Text
      mx={{ md: "20px", base: "0" }}
      mb={{ md: "0", base: "20px" }}
      cursor="pointer"
      color={{ md: "rgba(85, 85, 85, 1)", base: "rgba(255, 255, 255, 1)" }}
      fontFamily="Urbanist"
      fontWeight={{ md: "500", base: "900" }}
      textTransform="uppercase"
      fontSize={{ md: "16px", base: "20px" }}
      lineHeight={{ md: "19.2px", base: "24px" }}
      onClick={() => {
        handleScroll(nav);
        onClick && onClick();
      }}
    >
      {nav.replace(/-/g, " ")}
    </Text>
  );
};

export default TextNav;
