import React, { FC } from "react";
import { Box, Image, Text } from "@chakra-ui/react";

//local
import AboutPhoto from "../../assets/img/AboutPhoto.webp";
import AboutCleaning from "../../assets/img/AboutCleaning.webp";
import SectionComponent from "../ui/SectionComponent";

const AboutUs: FC = () => {
  return (
    <SectionComponent idName="about-us">
      <Box
        pt="100px"
        display="flex"
        flexDirection={{ lg: "row", base: "column" }}
        justifyContent="space-between"
      >
        <Box
          maxW={{ lg: "50%", base: "100%" }}
          display={{ lg: "flex", base: "none" }}
          justifyContent="center"
        >
          <Image
            maxW="100%"
            objectFit="cover"
            borderRadius="30px"
            src={AboutPhoto}
            alt={AboutPhoto}
          />
        </Box>
        <Box
          maxW={{ lg: "50%", base: "100%" }}
          mt={{ lg: "0", base: "20px" }}
          color="rgba(255, 255, 255, 1)"
          fontFamily="Urbanist"
          pl={{ lg: "10px" }}
          display="flex"
          flexDir="column"
        >
          <Box
            bg="rgba(99, 160, 210, 1)"
            px="1.5rem"
            pt="2rem"
            pb="4rem"
            rounded="30px"
          >
            <Text
              fontSize={{ md: "68px", sm: "48px", base: "38px" }}
              fontWeight="700"
              lineHeight={{ md: "72px", base: "45.6px", sm: "56px" }}
            >
              About Us
            </Text>
            <Text
              fontSize={{ md: "18px", base: "16px" }}
              mt="2rem"
              fontWeight="500"
              lineHeight={{ md: "29px", base: "25px" }}
            >
              Maxi Cleaning Solution is a premier house cleaning service
              dedicated to providing exceptional cleaning solutions for
              homeowners in [location]. We understand the importance of a clean
              and organized home, and our mission is to help you maintain a
              pristine living environment that you can be proud of.
            </Text>
          </Box>
          <Image
            maxW="100%"
            mt="10px"
            display={{ lg: "none", base: "block" }}
            objectFit="cover"
            borderRadius="30px"
            src={AboutPhoto}
            alt={AboutPhoto}
          />
          <Box
            display="flex"
            pt="10px"
            justifyContent="space-between"
            flexDir={{ sm: "row", base: "column" }}
          >
            <Box
              maxW={{ sm: "50%", base: "100%" }}
              mr={{ sm: "10px" }}
              bg="rgba(73, 140, 255, 1)"
              borderRadius="30px"
              p="1.5rem"
              display="flex"
              alignItems="center"
            >
              <Text
                fontSize={{ md: "24px", base: "16px" }}
                lineHeight={{ md: "35px", base: "23px" }}
                fontWeight="400"
              >
                With years of experience, we offer unmatched expertise and
                satisfaction.
              </Text>
            </Box>
            <Box
              display="flex"
              justifyContent="center"
              pt={{ base: "10px", sm: "0" }}
            >
              <Image
                borderRadius="30px"
                objectFit="cover"
                src={AboutCleaning}
                alt={AboutCleaning}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </SectionComponent>
  );
};

export default AboutUs;
