import React, { FC, useState } from "react";
import { Image } from "@chakra-ui/react";

//local
import WomenPhoto from "../../assets/img/womenPhoto.webp";
import SectionComponent from "../ui/SectionComponent";
import ModalComponent from "../ui/ModalComponent";
import FormFeedback from "../get-in-touch/FormFeedback";
import HeroTexts from "./HeroTexts";

const Hero: FC = () => {
  const [isOpen, setOpen] = useState<boolean>(false);

  const handleClick = () => {
    setOpen(!isOpen);
  };

  return (
    <SectionComponent idName="home">
      <HeroTexts onOpen={handleClick} />
      <Image
        src={WomenPhoto}
        alt={WomenPhoto}
        height={{ lg: "650px", md: "600px" }}
        display={{ md: "block", base: "none" }}
        zIndex="0"
        position="absolute"
        bottom="0"
        right={{ xl: "12.5%", lg: "9%", md: "0" }}
      />
      <ModalComponent
        isOpen={isOpen}
        onClose={handleClick}
        children={<FormFeedback bg="#ffffff" />}
      />
    </SectionComponent>
  );
};

export default Hero;
