import Standart from "../../assets/img/Standart.webp";
import Deep from "../../assets/img/Deep.webp";
import MoveIn from "../../assets/img/MoveIt.webp";

import DocStandart from "../../assets/doc/maxi1.pdf";
import DocMoveInOut from "../../assets/doc/maxi2.pdf";
import DocDeepClening from "../../assets/doc/maxi3.pdf";

export const dataList = [
  {
    image: Standart,
    title: "Standard Cleaning",
    description:
      "Weekly, bi-weekly, or monthly cleaning for a consistently tidy home.",
    price: "50",
    document: DocStandart,
  },
  {
    image: Deep,
    title: "Deep Cleaning",
    description:
      "Comprehensive cleaning for hard-to-reach areas and a pristine environment",
    price: "50",
    document: DocDeepClening,
  },
  {
    image: MoveIn,
    title: "Move-In/Move-Out Cleaning",
    description:
      "Window cleaning and organization assistance available upon request.",
    price: "50",
    document: DocMoveInOut,
  },
];
