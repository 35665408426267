import React, { FC } from "react";
import { Box } from "@chakra-ui/react";
import Slider from "react-slick";

//local
import SectionComponent from "../ui/SectionComponent";
import TextTitle from "../ui/TextTitle";
import { dataListCustomer } from "./dataListCustomer";
import CustomCard from "./CustomCard";

const OurCustomer: FC = () => {
  const combinedArray = [...dataListCustomer, ...dataListCustomer];

  const settings = {
    className: "center",
    centerMode: true,
    dots: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 4000,
    centerPadding: "0px",
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <SectionComponent idName="our-customer">
      <Box pt="100px" fontFamily="Urbanist">
        <TextTitle
          text="WHAT OUR CUSTOMERS SAY"
          size="32px"
          line="38px"
          color="rgba(85, 85, 85, 1)"
        />
        <Box pt="40px">
          <Slider {...settings}>
            {combinedArray.map((el, idx) => (
              <CustomCard key={idx} elem={el} />
            ))}
          </Slider>
        </Box>
      </Box>
    </SectionComponent>
  );
};

export default OurCustomer;
