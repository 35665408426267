import React, { FC } from "react";
import { Box } from "@chakra-ui/react";

//local
import { handleScroll } from "../../config/helpers";
import LeftNav from "./LeftNav";
import RightNav from "./RightNav";
import BurgerBtn from "./BurgerBtn";
import { LogoIconSvg } from "../../assets/svg/LogoIconSvg";

const Header: FC = () => {
  return (
    <header id="header">
      <Box
        position="fixed"
        top={{ md: "10px", base: "0" }}
        left={{ md: "0", base: "auto" }}
        right={{ md: "0", base: "auto" }}
        w="100%"
        zIndex="3"
      >
        <Box
          bg="linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9))"
          display="flex"
          borderTopRadius={{ md: "20px", base: "0" }}
          borderBottomRadius="20px"
          border="1px solid rgba(17, 85, 203, 0.1)"
          justifyContent={{ md: "center", base: "space-between" }}
          alignItems="center"
          color="rgba(85, 85, 85, 1)"
          py={{ md: "20px", base: "15px" }}
          fontFamily="Urbanist"
          fontSize="16px"
          fontWeight="500"
          textTransform="uppercase"
          lineHeight="19.2px"
          maxW={{
            base: "100%",
            md: "98%",
            lg: "95%",
            xl: "1120px",
            "2xl": "1360px",
          }}
          mx="auto"
        >
          <Box display={{ md: "none", base: "block" }}>
            <BurgerBtn />
          </Box>
          <Box display={{ md: "flex", base: "none" }}>
            <LeftNav />
          </Box>
          <Box mx="20px" cursor="pointer" onClick={() => handleScroll("home")}>
            <LogoIconSvg />
          </Box>
          <Box display={{ md: "flex", base: "none" }}>
            <RightNav />
          </Box>
        </Box>
      </Box>
    </header>
  );
};

export default Header;
