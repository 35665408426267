import { Suspense, lazy } from "react";
import { createRoot } from "react-dom/client";
import { ChakraProvider } from "@chakra-ui/react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

//local
import "./style/index.scss";
import { Loading } from "./components";
const App = lazy(() => import("./App"));

const root = createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <ChakraProvider>
    <Suspense fallback={<Loading />}>
      <App />
    </Suspense>
  </ChakraProvider>
);
