import { Box } from "@chakra-ui/react";
import React, { FC } from "react";

//local
import SectionComponent from "../ui/SectionComponent";
import TextTitle from "../ui/TextTitle";
import FormFeedback from "./FormFeedback";
import Contacts from "./Contacts";

const GetInTouch: FC = () => {
  return (
    <SectionComponent idName="contact">
      <Box pt="100px" pb="50px" fontFamily="Urbanist">
        <TextTitle
          text="GET IN TOUCH"
          size="32px"
          line="38px"
          color="rgba(0, 0, 0, 1)"
        />
        <Box
          display="flex"
          flexDirection={{ base: "column", md: "row" }}
          justifyContent="space-between"
          alignItems={{ base: "center", md: "flex-start" }}
          pt="40px"
        >
          <Contacts />
          <FormFeedback />
        </Box>
      </Box>
    </SectionComponent>
  );
};

export default GetInTouch;
