import {
  Box,
  Button,
  FormLabel,
  Input,
  Select,
  Text,
  useToast,
} from "@chakra-ui/react";
import React, { ChangeEvent, FC, FormEvent, useState } from "react";

type IProps = {
  bg?: string;
};

interface IFormFeedBack {
  name: string;
  email: string;
  square: string;
  phone: string;
  address: string;
  types: string;
  bedroom: number;
  bathroom: number;
}

const FormFeedback: FC<IProps> = ({ bg }) => {
  const [isLoading, setLoading] = useState<boolean>(false);

  const [value, setValue] = useState<IFormFeedBack>({
    name: "",
    email: "",
    square: "",
    phone: "",
    address: "",
    types: "",
    bedroom: 0,
    bathroom: 0,
  });

  const toast = useToast();

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue({ ...value, [e.target.name]: e.target.value });
  };

  const handleTypeChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const selectedType = event.target.value;
    setValue((prevValue) => ({
      ...prevValue,
      types: selectedType,
    }));
  };

  const bedRoomIncrement = () => {
    setValue({ ...value, bedroom: value.bedroom + 1 });
  };

  const bedRoomDecrement = () => {
    setValue({
      ...value,
      bedroom: value.bedroom === 0 ? 0 : value.bedroom - 1,
    });
  };

  const bathRoomIncrement = () => {
    setValue({ ...value, bathroom: value.bathroom + 1 });
  };

  const bathRoomDecrement = () => {
    setValue({
      ...value,
      bathroom: value.bathroom === 0 ? 0 : value.bathroom - 1,
    });
  };

  const postData = () => {
    setLoading(true);
    const url = "https://maxicleaningsolution.pythonanywhere.com/message_send/";
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(value),
    })
      .then((response) => {
        setLoading(false);
        toast({
          title: "Data sent successfully",
          status: "success",
          isClosable: true,
        });
      })
      .catch((e) => {
        setLoading(false);
        toast({
          title: `Error sending! ${e.message}`,
          status: "error",
          isClosable: true,
        });
      });
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    postData();
  };

  return (
    <Box
      maxW="629px"
      w={{ md: "629px", sm: "460px", base: "100%" }}
      bg={bg ? bg : "rgba(237, 241, 252, 1)"}
      p={{ sm: "2rem", base: "1.5rem" }}
      borderRadius="30px"
      fontFamily="Urbanist"
    >
      <form onSubmit={handleSubmit}>
        <Box>
          <Box display="flex" justifyContent="space-between" flexWrap="wrap">
            <Box pb="10px" w={{ lg: "48%", base: "100%" }}>
              <FormLabel
                fontWeight="600"
                fontSize="14px"
                color="rgba(17, 85, 203, 1)"
                htmlFor="name"
              >
                Name
              </FormLabel>
              <Input
                id="name"
                type="text"
                border="1px solid rgba(17, 85, 203, 1)"
                outline="0.5px solid rgba(17, 85, 203, 1)"
                borderRadius="42px"
                name="name"
                value={value.name}
                onChange={handleChange}
                required
              />
            </Box>
            <Box pb="10px" w={{ lg: "48%", base: "100%" }}>
              <FormLabel
                fontWeight="600"
                fontSize="14px"
                color="rgba(17, 85, 203, 1)"
                htmlFor="phone"
              >
                Phone number
              </FormLabel>
              <Input
                id="phone"
                type="tel"
                border="1px solid rgba(17, 85, 203, 1)"
                outline="0.5px solid rgba(17, 85, 203, 1)"
                borderRadius="42px"
                name="phone"
                value={value.phone}
                onChange={handleChange}
                required
                pattern="+[1-9]\d*"
              />
            </Box>
          </Box>
          <Box pb="10px">
            <FormLabel
              fontWeight="600"
              fontSize="14px"
              color="rgba(17, 85, 203, 1)"
              htmlFor="email"
            >
              Email
            </FormLabel>
            <Input
              id="email"
              type="email"
              border="1px solid rgba(17, 85, 203, 1)"
              outline="0.5px solid rgba(17, 85, 203, 1)"
              borderRadius="42px"
              name="email"
              value={value.email}
              onChange={handleChange}
              required
            />
          </Box>
          <Box pb="10px" pt="20px">
            <FormLabel
              fontWeight="600"
              fontSize="14px"
              color="rgba(17, 85, 203, 1)"
              htmlFor="address"
            >
              Address cleaning
            </FormLabel>
            <Input
              id="address"
              type="text"
              border="1px solid rgba(17, 85, 203, 1)"
              outline="0.5px solid rgba(17, 85, 203, 1)"
              borderRadius="42px"
              name="address"
              value={value.address}
              onChange={handleChange}
              required
            />
          </Box>
          <Box display="flex" justifyContent="space-between" flexWrap="wrap">
            <Box pb="10px" w={{ lg: "48%", base: "100%" }}>
              <FormLabel
                fontWeight="600"
                fontSize="14px"
                color="rgba(17, 85, 203, 1)"
                htmlFor="type"
              >
                Types of cleaning
              </FormLabel>
              <Select
                id="type"
                borderRadius="42px"
                border="1px solid rgba(17, 85, 203, 1)"
                outline="0.5px solid rgba(17, 85, 203, 1)"
                onChange={handleTypeChange}
              >
                <option value="">{value.types}</option>
                <option value="Standard Cleaning">Standard Cleaning</option>
                <option value="Deep Cleaning">Deep Cleaning</option>
                <option value="Move-In/Move-Out Cleaning">
                  Move-In/Move-Out Cleaning
                </option>
                <option value="Specialized Services">
                  Specialized Services
                </option>
              </Select>
            </Box>
            <Box pb="10px" w={{ lg: "48%", base: "100%" }}>
              <FormLabel
                fontWeight="600"
                fontSize="14px"
                color="rgba(17, 85, 203, 1)"
                htmlFor="square"
              >
                Square
              </FormLabel>
              <Input
                id="square"
                type="number"
                border="1px solid rgba(17, 85, 203, 1)"
                outline="0.5px solid rgba(17, 85, 203, 1)"
                borderRadius="42px"
                name="square"
                value={value.square}
                onChange={handleChange}
                required
                pattern="[1-9]\d*"
              />
            </Box>
          </Box>
          <Box
            display="flex"
            justifyContent={{ lg: "space-between", base: "normal" }}
            flexWrap="wrap"
          >
            <Box pt="10px" w={{ lg: "48%", base: "100%" }}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent={{ lg: "space-around", base: "space-between" }}
                borderRadius="42px"
                border="2px solid rgba(17, 85, 203, 1)"
                outline="none"
                overflow="hidden"
              >
                <FormLabel
                  fontWeight="600"
                  fontSize="14px"
                  color="rgba(17, 85, 203, 1)"
                  m="0"
                  pl={{ lg: "0", base: "10px" }}
                >
                  Bedroom
                </FormLabel>
                <Box display="flex" alignItems="center">
                  <Button
                    isDisabled={value.bedroom === 0}
                    onClick={bedRoomDecrement}
                    color="rgba(17, 85, 203, 1)"
                    p="0"
                  >
                    <span
                      style={{
                        width: "20px",
                        height: "20px",
                        border: "2px solid rgba(17, 85, 203, 1)",
                        borderRadius: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      -
                    </span>
                  </Button>
                  <Text
                    textAlign="center"
                    fontWeight="500"
                    fontSize="16px"
                    lineHeight="24px"
                    mx="5px"
                  >
                    {value.bedroom}
                  </Text>
                  <Button
                    onClick={bedRoomIncrement}
                    color="rgba(17, 85, 203, 1)"
                    p="0"
                  >
                    <span
                      style={{
                        width: "20px",
                        height: "20px",
                        border: "2px solid rgba(17, 85, 203, 1)",
                        borderRadius: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      +
                    </span>
                  </Button>
                </Box>
              </Box>
            </Box>
            <Box pt="10px" w={{ lg: "48%", base: "100%" }}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent={{ lg: "space-around", base: "space-between" }}
                borderRadius="42px"
                border="2px solid rgba(17, 85, 203, 1)"
                outline="none"
                overflow="hidden"
              >
                <FormLabel
                  fontWeight="600"
                  fontSize="14px"
                  color="rgba(17, 85, 203, 1)"
                  m="0"
                  pl={{ lg: "0", base: "10px" }}
                >
                  Bathroom
                </FormLabel>
                <Box display="flex" alignItems="center">
                  <Button
                    isDisabled={value.bathroom === 0}
                    color="rgba(17, 85, 203, 1)"
                    onClick={bathRoomDecrement}
                    p="0"
                  >
                    <span
                      style={{
                        width: "20px",
                        height: "20px",
                        border: "2px solid rgba(17, 85, 203, 1)",
                        borderRadius: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      -
                    </span>
                  </Button>
                  <Text
                    textAlign="center"
                    fontWeight="500"
                    fontSize="16px"
                    lineHeight="24px"
                    mx="5px"
                  >
                    {value.bathroom}
                  </Text>
                  <Button
                    onClick={bathRoomIncrement}
                    color="rgba(17, 85, 203, 1)"
                    p="0"
                  >
                    <span
                      style={{
                        width: "20px",
                        height: "20px",
                        border: "2px solid rgba(17, 85, 203, 1)",
                        borderRadius: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      +
                    </span>
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box display="flex" justifyContent="center">
          <Button
            bg="rgba(0, 92, 165, 1)"
            w={{ md: "50%", base: "100%" }}
            type="submit"
            mt="25px"
            mx="auto"
            py="15px"
            px="35px"
            colorScheme="blue.500"
            borderRadius="50px"
            isLoading={isLoading}
          >
            Order cleaning
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default FormFeedback;
