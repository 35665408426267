import { Box, Text } from "@chakra-ui/react";
import React, { FC } from "react";
import { PhoneIconSvg } from "../../assets/svg/PhoneIconSvg";
import { navigateToUrl } from "../../config/helpers";
import { EmailIconSvg } from "../../assets/svg/EmailIconSvg";
import { LocationIconSvg } from "../../assets/svg/LocationIconSvg";
import { ADDRESS, EMAIL, PHONE } from "../../config/constants";

const Contacts: FC = () => {
  const dataList = [
    {
      icon: <PhoneIconSvg />,
      title: "Phone",
      text: PHONE,
      onClick: () => navigateToUrl(`tel:${PHONE}`, true),
    },
    {
      icon: <EmailIconSvg />,
      title: "Mail",
      text: EMAIL,
      onClick: () => navigateToUrl(`mailto:${EMAIL}`, true),
    },
    {
      icon: <LocationIconSvg />,
      title: "Location",
      text: ADDRESS,
      onClick: () => navigateToUrl("https://www.google.com/maps"),
    },
  ];
  return (
    <Box maxW="390px">
      <Text
        color="rgba(51, 51, 51, 1)"
        maxW="464px"
        fontWeight="600"
        fontSize="20px"
        lineHeight="30px"
        mb="40px"
      >
        Fill up the form and our Team will get back to you within 24 hours.
      </Text>
      {dataList.map((el, idx) => (
        <Box key={idx} mb="20px" display="flex" alignItems="flex-start">
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minW={{ sm: "90px", base: "70px" }}
            minH={{ sm: "90px", base: "70px" }}
            borderRadius="8px"
            bg="rgba(237, 241, 252, 1)"
          >
            {el.icon}
          </Box>
          <Box pl="15px" pr={{ sm: "10px", base: "1px" }} py="10px">
            <Text
              maxW="100%"
              fontWeight="700"
              fontSize="24px"
              lineHeight="24px"
              color="rgba(17, 85, 203, 1)"
            >
              {el.title}
            </Text>
            <Text
              mt="5px"
              maxW="100%"
              color="rgba(51, 51, 51, 1)"
              fontWeight="400"
              fontSize="16px"
              lineHeight="27.2px"
              cursor="pointer"
              onClick={el.onClick}
            >
              {el.text}
            </Text>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default Contacts;
