export const PhoneIconSvg = () => {
  return (
    <svg
      width="25"
      height="26"
      viewBox="0 0 25 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.6677 18.2673L18.8205 16.1137C19.1104 15.8273 19.4773 15.6312 19.8765 15.5492C20.2757 15.4673 20.6901 15.5031 21.0693 15.6522L23.693 16.7002C24.0762 16.8558 24.4049 17.1215 24.6375 17.4637C24.8701 17.8059 24.9962 18.2093 25 18.6231V23.4302C24.9978 23.7117 24.9386 23.9898 24.8261 24.2479C24.7136 24.5059 24.5501 24.7384 24.3454 24.9316C24.1406 25.1247 23.899 25.2744 23.6349 25.3716C23.3708 25.4688 23.0898 25.5116 22.8088 25.4973C4.424 24.3532 0.714362 8.77798 0.0127987 2.81709C-0.0197684 2.52437 0.0099873 2.22807 0.100108 1.94768C0.190229 1.66729 0.338672 1.40916 0.535672 1.19029C0.732673 0.971411 0.973765 0.796742 1.24309 0.677774C1.51241 0.558805 1.80386 0.498232 2.09827 0.500039H6.74012C7.15434 0.501266 7.55872 0.626436 7.90125 0.859451C8.24378 1.09247 8.5088 1.42267 8.66221 1.80759L9.70975 4.4323C9.86377 4.81021 9.90306 5.22514 9.82272 5.62526C9.74239 6.02538 9.54599 6.39295 9.25806 6.68206L7.10532 8.83567C7.10532 8.83567 8.34507 17.229 16.6677 18.2673Z"
        fill="#1155CB"
      />
    </svg>
  );
};
