export const LocationIconSvg = () => {
  return (
    <svg
      width="25"
      height="33"
      viewBox="0 0 25 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5 0.5C9.186 0.503923 6.00885 1.82648 3.66549 4.17757C1.32214 6.52865 0.00392292 9.71628 1.32987e-05 13.0412C-0.00395589 15.7583 0.880672 18.4018 2.51819 20.5659C2.51819 20.5659 2.8591 21.0163 2.91478 21.0813L12.5 32.4231L22.0898 21.0756C22.1398 21.0151 22.4818 20.5659 22.4818 20.5659L22.4829 20.5625C24.1197 18.3993 25.0039 15.7571 25 13.0412C24.9961 9.71628 23.6779 6.52865 21.3345 4.17757C18.9912 1.82648 15.814 0.503923 12.5 0.5ZM12.5 17.6016C11.601 17.6016 10.7222 17.3342 9.97468 16.8331C9.22719 16.332 8.64459 15.6197 8.30055 14.7864C7.95652 13.9531 7.8665 13.0361 8.04189 12.1515C8.21728 11.2669 8.65019 10.4543 9.28588 9.81649C9.92157 9.1787 10.7315 8.74436 11.6132 8.5684C12.495 8.39243 13.4089 8.48274 14.2395 8.82791C15.07 9.17308 15.7799 9.7576 16.2794 10.5076C16.7789 11.2575 17.0455 12.1392 17.0455 13.0412C17.0439 14.2502 16.5646 15.4093 15.7125 16.2643C14.8603 17.1192 13.7051 17.6001 12.5 17.6016Z"
        fill="#1155CB"
      />
    </svg>
  );
};
